<template>
    <b-col cols="12" md="12" class="mb-sm-2">
        <b-card>
            <template #header>
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <h6 class="mb-0">
                            {{ $t("preference") }}:
                            {{ preferenceEditable ? "" : innerValue.rank }}
                            <template v-if="innerValue.result">
                                <b-badge class="ml-3"
                                    :variant="(innerValue.result == 'placed' || innerValue.result == 'registered' ? 'success' : 'danger') + ' text-uppercase'">
                                    {{ $t(`application_result.${innerValue.result}`) }}
                                </b-badge>
                            </template>
                        </h6>
                        <b-button v-if="innerValue.result == 'placed'" class="btn-xs ml-3" variant="primary" @click="register()" >{{ $t('register_online').toLocaleUpperCase('en-EN') }}</b-button>
                        <div class="ml-3" style="width: 9rem" v-if="preferenceEditable">
                            <b-form-select v-model="innerIndex" :options="[1, 2, 3]" />
                        </div>
                    </div>
                    <button class="btn btn-danger" @click="remove" v-if="innerValue.status === 'waiting_save'">
                        <b-icon icon="trash-fill" size="30" style="width: 1.2rem; height: 1.2rem" class="mr-1"
                            aria-hidden="true">
                        </b-icon>
                        {{ $t("preferences_delete") }}
                    </button>
                </div>
            </template>
            <b-card-text>
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox :disabled="true" v-model="innerValue.faculty_code" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox :disabled="true" v-model="innerValue.program_code" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </b-col>
</template>
<script>
import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";

export default {
    name: "DoubleMajorPreference",
    props: {
        value: {
            type: Object,
            default: () => { },
        },
        index: {
            type: Number,
            default: 0,
        },
        preferenceEditable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("updateValue", val);
                return val;
            },
        },
        innerIndex: {
            get() {
                return this.value.rank;
            },
            set(val) {
                this.innerValue.rank = val;
                return val;
            },
        },
    },
    watch: {
        innerIndex: {
            handler(newVal, oldVal) {
                if (oldVal && newVal) {
                    this.$emit("changeRank", {
                        id: this.value.id,
                        old: oldVal,
                        new: newVal,
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        remove() {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        DoubleMajorApplicationService.deletePreferences(
                            this.innerValue.id
                        )
                            .then((response) => {
                                this.$toast.success(this.$t("api." + response.data.message));
                                this.$emit('getItems')
                            })
                            .catch((e) => {
                                this.showErrors(
                                    e,
                                    this.$refs.customerPriceForm
                                );
                            });
                    }
                });
        },
        register() {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_online_registration'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                       DoubleMajorApplicationService.onlineRegistration(this.innerValue.id)
                            .then(response => {
                                this.innerValue.result = 'registered';
                                this.$toast.success(this.$t('api.' + response.data.message));
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            })
                    }
                });
        },
    },
};
</script>
<style lang=""></style>
